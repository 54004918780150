import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useEffect, useRef, useState } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { api, api_delete } from '../services/api.service'

import Confirm from '../components/confirm'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

import { NumberField, TextField } from '../forms/fields'
import FormModal from '../forms/form-modal'

import { BASE_URL } from '../app/home'
import { style } from '../style'

export default function AdminOwnerships() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [deleteId, setDeleteId] = useState()
    const [copyOpen, setCopyOpen] = useState(false)
    const [copyCount, setCopyCount] = useState()
    const [batchNumber, setBatchNumber] = useState('#')
    const [qrExportOpen, setQrExportOpen] = useState()
    const [selectedCells, setSelectedCells] = useState([])
    const [dataUrls, setDataUrls] = useState([])
    const [filter, setFilter] = useState('')

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/ownership`).then((x) => setData(x))
    }, [])

    function deleteConfirm() {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/ownership/${deleteId}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/admin/ownership`)
                .then((x) => setData(x))
                .then((x) => setDeleteId(null))
        })
    }

    const submitCopy = () => {
        api(`${process.env.REACT_APP_API_URL}/admin/ownership-copy/${copyOpen}/${copyCount}/${encodeURIComponent(batchNumber)}`).then((x) => {
            setCopyCount(null)
            setBatchNumber('#')
            setCopyOpen(false)
            api(`${process.env.REACT_APP_API_URL}/admin/ownership`).then((x) => setData(x))
        })
    }

    const exportToZip = () => {
        const zip = new JSZip()
        let count = 1
        const img = zip.folder('images')
        for (let url of dataUrls) {
            img.file(count + '.png', url.url.replace(/^data:image\/?[A-z]*;base64,/, ''), { base64: true })
            count++
        }
        zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, 'QR Export.zip')
        })
        setDataUrls([])
        setSelectedCells([])
    }

    const getProductName = (item) => {
        if (!item.product && !item.name) {
            return 'Custom Item'
        } else if (!item.product) {
            return item.name
        } else return item.product.name
    }
    if (!data) return <Loading></Loading>

    return (
        <>
            <FormModal open={!!qrExportOpen} setOpen={setQrExportOpen}>
                <div className='p-5 bg-white rounded-md'>
                    <div className='mb-5 font-semibold text-neutral-700'>QR Codes Ready</div>
                    <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => exportToZip()}>
                        Download as Zip
                    </button>

                    <div className='hidden'>
                        {selectedCells.map((x) => {
                            let ownership = data.find((y) => y._id == x)
                            return <CustomQrCode setDataUrl={(url) => setDataUrls((z) => (z.find((y) => y.id == x) ? z.map((y) => (y.id == x ? url : y)) : z.concat([url])))} id={x} qrProps={ownership.product?.defaultQrSettings ?? { style: 'square', logo: '', text: '' }}></CustomQrCode>
                        })}
                    </div>
                </div>
            </FormModal>

            <FormModal open={!!copyOpen} setOpen={setCopyOpen}>
                <div className='p-5 bg-white rounded-md'>
                    <NumberField label='How Many Copies?' value={copyCount} onChange={(val) => setCopyCount(val)} />
                    <TextField label='Batch Number' value={batchNumber} onChange={(val) => setBatchNumber(val)} />

                    <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => submitCopy()}>
                        Submit
                    </button>
                </div>
            </FormModal>

            <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

            <PageHeader
                headline={'Ownerships'}
                actions={
                    <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => navigate('/admin/manage-ownership/new')}>
                        Add Ownership
                    </button>
                }
            ></PageHeader>

            <div className='flex'>
                <div className='w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl'>
                    <div>
                        <div className='flex items-center justify-between space-x-4'>
                            <div>
                                <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => setQrExportOpen(true)}>
                                    Export QR Codes
                                </button>
                            </div>
                            <div>
                                <TextField label='Search' value={filter} onChange={setFilter} />
                            </div>
                        </div>
                        <Table
                            hideSearch={true}
                            exportColumns={['Product Name', 'Owner Name', 'Lost', 'Found', 'URL', 'Batch Number']}
                            exportData={data.map((x) => {
                                return {
                                    productName: getProductName(x),
                                    firstName: x.currentOwner?.name || 'N/A',
                                    lastName: x.currentOwner?.lastName || 'N/A',
                                    email: x.currentOwner?.email || 'N/A',
                                    lost: x.isLost ? 'Yes' : 'No',
                                    found: x.isFound ? 'Yes' : 'No',
                                    url: `${BASE_URL}/ownership/${x._id}`,
                                    batchNumber: x.batchNumber,
                                }
                            })}
                            data={data
                                .filter((x) => getProductName(x).toLowerCase().includes(filter.toLowerCase()))
                                .map((x) => {
                                    return {
                                        checkboxCell: <input type='checkbox' checked={selectedCells.includes(x._id)} onChange={(e) => (!e.target.checked ? setSelectedCells(selectedCells.filter((y) => y != x._id)) : setSelectedCells(selectedCells.concat([x._id])))}></input>,
                                        batchNumber: x.batchNumber,
                                        productName: getProductName(x),
                                        firstName: x.currentOwner?.name || 'N/A',
                                        lastName: x.currentOwner?.lastName || 'N/A',
                                        email: x.currentOwner?.email || 'N/A',
                                        lost: x.isLost ? 'Yes' : 'No',
                                        found: x.isFound ? 'Yes' : 'No',
                                        url: (
                                            <a href={`${BASE_URL}/ownership/${x._id}`} target={'_blank'}>
                                                {`${BASE_URL}/ownership/${x._id}`}
                                            </a>
                                        ),
                                        copy: (
                                            <span className='underline cursor-pointer' onClick={() => setCopyOpen(x._id)}>
                                                Copy
                                            </span>
                                        ),

                                        edit: (
                                            <span className='underline cursor-pointer' onClick={() => navigate(`/admin/manage-ownership/${x._id}`)}>
                                                Edit
                                            </span>
                                        ),
                                        deleteCell: (
                                            <span className='underline cursor-pointer' onClick={() => setDeleteId(x._id)}>
                                                Delete
                                            </span>
                                        ),
                                    }
                                })}
                            columns={[
                                {
                                    Header: <input type='checkbox' checked={selectedCells.length == data.filter((x) => x.product?.name?.toLowerCase().includes(filter.toLowerCase())).length} onChange={(e) => (!e.target.checked ? setSelectedCells([]) : setSelectedCells(data.filter((x) => x.product?.name?.toLowerCase().includes(filter.toLowerCase())).map((x) => x._id)))}></input>,
                                    accessor: 'checkboxCell',
                                },
                                {
                                    Header: 'Product',
                                    accessor: 'productName',
                                },
                                {
                                    Header: 'Owner Name',
                                    accessor: 'firstName',
                                },
                                {
                                    Header: 'Owner Last Name',
                                    accessor: 'lastName',
                                },
                                {
                                    Header: 'Owner Email',
                                    accessor: 'email',
                                },
                                {
                                    Header: 'Lost',
                                    accessor: 'lost',
                                },
                                {
                                    Header: 'Found',
                                    accessor: 'found',
                                },
                                {
                                    Header: 'URL',
                                    accessor: 'url',
                                },
                                {
                                    Header: 'Batch Number',
                                    accessor: 'batchNumber',
                                },
                                {
                                    Header: 'Copy',
                                    accessor: 'copy',
                                },
                                {
                                    Header: 'Edit',
                                    accessor: 'edit',
                                },
                                {
                                    Header: 'Delete',
                                    accessor: 'deleteCell',
                                },
                            ]}
                        ></Table>
                    </div>
                </div>
            </div>
        </>
    )
}

const CustomQrCode = ({ qrProps, id, setDataUrl }) => {
    const svgRef = useRef()

    useEffect(() => {
        if (svgRef.current) {
            const canvas = svgRef.current.canvas.current
            const context = canvas.getContext('2d')

            context.font = '200px Arial'
            context.fillStyle = 'black'
            context.textAlign = 'center'
            context.fillText(qrProps.text, 1250, 2450)

            setDataUrl({ id: id, url: svgRef.current.canvas.current.toDataURL('image/png') })
        }
    }, [svgRef.current])

    return <QRCode bgColor={qrProps.transparentBackground ? '#FFFFFF00' : '#FFFFFF'} ref={svgRef} ecLevel='Q' size='2000' logoPaddingStyle={qrProps.style == 'round' ? 'circle' : 'squares'} logoPadding={2} removeQrCodeBehindLogo={true} value={`${BASE_URL}/ownership/${id}`} eyeRadius={qrProps.style == 'round' ? 10 : 0} qrStyle={qrProps.style == 'round' ? 'dots' : 'squares'} logoImage={qrProps.logo} quietZone={250} />
}
