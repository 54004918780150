export default function SwitchField({ label, value, onChange, className, inputClass, labelClass, inline, onBlur }) {
    return (
        <div className={className + (inline ? ' flex place-items-center' : '') + ' mt-2'}>
            {!inline && <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>{label}</label>}
            <div className={inline ? '' : 'mt-1'}>
                <label className={`relative inline-flex items-center cursor-pointer ${inputClass}`}>
                    <input
                        type="checkbox"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        onBlur={onBlur}
                        className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full 
                     peer-checked:bg-brand-600 peer-checked:after:translate-x-5 after:content-[''] after:absolute after:top-0.5 after:left-[2px]
                      after:bg-white after:border after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"></div>
                </label>
            </div>
            {inline && <label className={`ml-3 block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>{label}</label>}
        </div>
    )
}