import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid"
import { useState } from "react"

export default function PasswordField({ label, value, onChange, className, inputClass, labelClass, placeholder, onBlur, required }) {

    const [showPassword, setShowPassword] = useState(false)

    return (
        <div className={className}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
                {label} {required && <span className='text-red-500'>*</span>}
            </label>
            <div className='mt-2 relative'>
                <input required={required} type={showPassword ? 'text' : 'password'} onBlur={onBlur} placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} className={`bg-slate-100 pr-7 block w-full rounded border-0 px-3 p-2 text-neutral-700 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-1 focus:ring-gray-200 sm:text-sm sm:leading-6 ${inputClass}`} />
                {showPassword && <EyeSlashIcon onClick={() => setShowPassword(x => !x)} className="cursor-pointer w-6 text-neutral-600 right-1 top-2 hover:text-neutral-700 absolute z-10" />}
                {!showPassword && <EyeIcon onClick={() => setShowPassword(x => !x)} className="cursor-pointer w-6 text-neutral-600 right-1 top-2 hover:text-neutral-700 absolute z-10" />}
            </div>
        </div>
    )
}
