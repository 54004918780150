import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { PasswordField, TextField } from '../forms/fields'

import { style } from '../style'
import AuthLayout from './layout'

export default function Register() {
    const [values, setValues] = useState({})
    const [error, setError] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    async function submit() {
        await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ user: values, metaData: { ref: searchParams.get('r'), client: searchParams.get('c') } }),
        })
            .then((res) => {
                return res.json()
            })
            .then((x) => {
                if (x.error) {
                    if (x.error === 'email_in_use') {
                        setError('Email already in use. Please sign in or register with a different email.')
                    }
                } else if (x.token) {
                    localStorage.setItem('token', x.token)
                    if (searchParams.get('rd')) {
                        window.location.replace(searchParams.get('rd'))
                    } else {
                        window.location.replace('/')
                    }
                } else {
                    setError('Registration failed. Please check your details and try again.')
                }
            })
    }

    return (
        <AuthLayout>
            <div className='text-xl font-medium text-center text-neutral-800'>Create your account</div>
            <div className='mb-3 text-sm font-light text-center text-neutral-500'>
                Already have an account?{' '}
                <a className='text-brand-400 hover:text-brand-500' href='/login'>
                    Sign In
                </a>
            </div>
            <div className='my-5 text-sm font-light text-center text-neutral-500'>
                <p className="text-base font-bold">Welcome to Boomerang Community!</p>

                <p>Here you can Tag, Retrieve and Rehome your belongings</p>

                <p>Don't have a tag yet?</p>

                <p className="underline text-brand"><a href="https://theboomerangtag.com/collections/boomerangtag" target='_blank'>Get BoomerangTag here</a></p>
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    submit()
                }}
                className='space-y-6'
            >
                <div>
                    <label className='block text-sm font-medium text-center text-neutral-800'>{error}</label>
                </div>

                <TextField required label='First Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                <TextField required label='Last Name' value={values.lastName} onChange={(val) => setValues({ ...values, lastName: val })}></TextField>

                <TextField required type='email' label='Email' value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>

                <div className='grid grid-cols-2 gap-4'>
                    <PasswordField required label='Password' value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
                    <PasswordField required label='Confirm Password' value={values.confirmPassword} onChange={(val) => setValues({ ...values, confirmPassword: val })}></PasswordField>
                    {values.password && values.confirmPassword && values.password != values.confirmPassword && <div className="my-2 text-sm text-red-500">Passwords do not match</div>}
                </div>

                <div className='flex'>
                    <input checked={values.tos} onChange={(e) => setValues({ ...values, tos: e.target.checked })} type='checkbox' className='block appearance-none rounded border mt-0.5 border-gray-200  placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm' />

                    <div className='ml-2 text-sm text-neutral-500'>
                        I accept the platform{' '}
                        <a target='_blank' href='/terms-of-service.html' className='text-brand-400 hover:text-brand-500'>
                            Terms of Service
                        </a>
                    </div>
                </div>

                <div>
                    <button type='submit' disabled={!values.email || !values.password || values.password != values.confirmPassword || !values.tos} className={twMerge(style.button.classname, 'flex justify-center w-full px-4 py-2  text-white bg-brand-500 border border-transparent shadow-sm disabled:bg-gray-500 text-md hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2')}>
                        Sign Up
                    </button>
                </div>
            </form>
        </AuthLayout>
    )
}
