import { ArrowUpIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { twMerge } from 'tailwind-merge'
import axios from 'axios'

import S3Viewer from '../../components/S3Viewer'
import { api } from '../../services/api.service'

export default function S3Field({ label, value, onChange, className, inputClass, labelClass, profile }) {
   const [uploading, setUploading] = useState(false)
   const [progress, setProgress] = useState(0)
   const [error, setError] = useState(null)

   const upload = async (file) => {
       setUploading(true)
       let fileName = `${file.name}_${new Date().toTimeString()}`
       let url = await api(`${process.env.REACT_APP_API_URL}/s3/get-signed-post-url/${encodeURIComponent(fileName)}/${encodeURIComponent(file.type)}`)
           .then((res) => res?.url ?? null)

       if (!url) return

       axios.put(url, file, {
           headers: { 'Content-Type': file.type },
           onUploadProgress: (progressEvent) => {
               setProgress(Math.round((progressEvent.loaded / (progressEvent.total ?? 1)) * 100))
           },
       })
       .then(() => {
           onChange(fileName)
           setUploading(false)
           setProgress(0)
       })
       .catch((err) => {
           setUploading(false)
           setError(err.message)
           console.warn(err)
       })
   }

   const handleFileChange = (e) => {
       const files = e.target.files
       if (files) {
           upload(files[0])
       }
   }

   return (
       <div className={className}>
           <ToastContainer position='top-center' autoClose={1000} />
           <label className={`block text-sm font-medium text-center leading-6 text-neutral-600 mt-5 ${labelClass}`}>{label}</label>
           <div className='mt-2'>
               <div className={`flex space-x-2 items-center ${profile ? '' : 'flex-col'}`}>
                   <div className={`shadow-lg relative ${profile ? 'rounded-full w-24 h-24' : 'rounded w-44 h-44'} overflow-hidden my-2 bg-neutral-200 flex items-center justify-center`}>
                       {uploading && (
                           <div className='absolute mx-auto'>
                               <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                                   <style>{'.spinner_ajPY{transform-origin:center;animation:spinner_AtaB .75s infinite linear}@keyframes spinner_AtaB{100%{transform:rotate(360deg)}}'}</style>
                                   <path d='M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z' opacity='.25' />
                                   <path d='M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z' className='spinner_ajPY' />
                               </svg>
                           </div>
                       )}

                       {value && (
                           <div className='absolute inset-0 z-10'>
                               <S3Viewer className={`h-full w-full object-cover ${uploading ? 'opacity-20' : ''}`} fileKey={value} />
                           </div>
                       )}

                       {!value && !error && (
                           <label className='flex flex-col justify-center w-full h-full text-center cursor-pointer'>
                               {!uploading && <ArrowUpIcon className='w-6 mx-auto text-neutral-400'></ArrowUpIcon>}
                               {!uploading && <p className='py-2 text-xs text-neutral-400'>click / tap to upload</p>}
                               <input className='absolute w-0 h-0 opacity-0' onChange={handleFileChange} type='file' />
                           </label>
                       )}

                       {error && (
                           <label className='flex flex-col justify-center w-full h-full text-center cursor-pointer'>
                               <ExclamationCircleIcon className='w-12 mx-auto my-5 text-red-500'></ExclamationCircleIcon>
                               <p className='py-5 text-lg text-neutral-800'>{error}</p>
                               <p className='py-3 text-base font-semibold text-neutral-800'>Retry</p>
                               <input className='absolute w-0 h-0 opacity-0' onChange={handleFileChange} type='file' />
                           </label>
                       )}
                   </div>

                   {value && (
                       <label className='block overflow-hidden text-center bg-white rounded shadow hover:shadow-md'>
                           <div className={twMerge('inline-flex items-center justify-center mt-2', 'py-2 px-4 bg-brand-500 text-white rounded-md cursor-pointer hover:bg-brand-700')}>
                               Replace
                           </div>
                           <input className='absolute w-0 h-0 opacity-0' onChange={handleFileChange} type='file' />
                       </label>
                   )}
               </div>
           </div>
       </div>
   )
}