import { twMerge } from 'tailwind-merge'

export default function AuxiliaryProductDetails({ product, className }) {
    return (
        product && (
            <div>
                {product?.size && <p><span className="font-semibold">Size:</span> {product.size}</p>}
                {product?.category && <p><span className="font-semibold">Category:</span> {product.category}</p>}
                {product?.colour && <p><span className="font-semibold">Colour:</span> {product.colour}</p>}
                {product?.style && <p><span className="font-semibold">Style:</span> {product.style}</p>}
                {product?.additionalDetails && (
                    <p className='max-w-xs'>
                        <span className="font-semibold">Additional Details:</span>  {product.additionalDetails}
                    </p>
                )}
            </div>
        )
    )
}
