import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import PageHeader from '../components/page-header'
import { ImageUpload, SliderField, TextAreaField, TextField } from './fields'
import FormWrapper from './form-wrapper'
import { api } from '../services/api.service'
import Button from '../components/button'
import { twMerge } from 'tailwind-merge'
import { style } from '../style'
import S3Field from './fields/s3-field'

export default function CustomOwnershipForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    let id = params.id ?? props.id

    const [productData, setProductData] = useState()
    const [userData, setUserData] = useState()
    const [isNewRegistration, setIsNewRegistration] = useState(false)

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const isNew = searchParams.get('new') === 'true'
        setIsNewRegistration(isNew)

        api(`${process.env.REACT_APP_API_URL}/app/profile`).then((x) => {
            setUserData(x)
        })
        api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => {
            console.log(x)
            setProductData(x.ownership.product)
        })
    }, [location.search])

    const handleCheckboxChange = (field, subField, value, setValues) => {
        setValues(prev => ({
            ...prev,
            overridePermissions: {
                ...prev.overridePermissions,
                [field]: {
                    ...prev.overridePermissions?.[field],
                    [subField]: value
                }
            }
        }))
    }

    return (
        <div className='sm:m-0 -m-5'>
            <FormWrapper hideButton={true} url={`app/ownership`} id={id} includeIdInPost={true} {...props} callback={() => window.location.reload()}>
                {(values, setValues, submit) => (
                    <div className='-p-5'>
                        {!values.isCustom && <div className='mt-10'>
                            <div className='text-xl text-neutral-800 font-medium'>{productData?.name}</div>
                            <div className='text-neutral-600'>{productData?.brand}</div>
                        </div>}

                        {values.isCustom && <div>
                            <TextField required label='Product' value={values?.name?.toLowerCase().includes("your item needs a name") ? "" : values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                            {/* <ImageUpload label='Upload a photo' value={values.photo} onChange={(val) => setValues({ ...values, photo: val })}></ImageUpload> */}
                            <S3Field label="Upload a photo" value={values.s3Image} onChange={(val) => setValues({ ...values, s3Image: val })} />
                            <TextAreaField label='Description' placeholder='Add a description of your item, you can include any distinguishing features or unique aspects of your item...' value={values.description} onChange={(val) => setValues({ ...values, description: val })} />
                        </div>}
                        <div onClick={() => submit()} className={twMerge('text-white border-none text-center mx-auto my-5', style.button.classname, style.button._secondary.classname)}>Save</div>

                        {!isNewRegistration && <div>
                            <div className="text-neutral-700 my-5 flex flex-col space-y-1">
                                <div className='text-xl font-bold'>
                                    Control what finders can see for this item.
                                </div>
                                <div>
                                    If you don't leave any details, that's also OK, the finder will still be able to send an anonymous email to you with return details.
                                </div>
                                <div>
                                    Visit “Settings” to change what finders can see by default.
                                </div>
                            </div>
                            <div className='flex'>
                                <div><button className={twMerge(style.button.classname, style.button._secondary.classname)} type="button" onClick={() => setValues(prev => ({ ...prev, overridePermissions: {}, overrideLastName: null, overrideName: null, returnInstructions: null }))}>
                                    Reset to default
                                </button></div>
                            </div>
                            <div className='flex flex-col space-y-10 divide-y'>
                                {([
                                    { label: "First Name", field: "name", value: values.overrideName || userData?.name, mutable: true },
                                    { label: "Last Name", field: "lastName", value: values.overrideLastName || userData?.lastName, mutable: true },
                                    { label: "Email", field: "email", value: userData?.email, mutable: false },
                                    { label: "Phone Number", field: "phoneNumber", value: userData?.phoneNumber, mutable: false },
                                    { label: "Return Instructions", field: "returnInstructions", value: values.returnInstructions || userData?.defaultReturnInstructions, mutable: true }
                                ]).map(({ label, field, value, mutable }) => (
                                    <div className='flex space-x-5' key={field}>
                                        <div className='flex-1 sm:text-base text-sm'>
                                            {mutable ?
                                                (mutable && field !== "returnInstructions" ?
                                                    <TextField label={label} value={value} onChange={(val) => setValues({ ...values, [`override${field.charAt(0).toUpperCase() + field.slice(1)}`]: val })} />
                                                    : <TextAreaField label={"Default return instructions"} value={value} onChange={(val) => setValues({ ...values, returnInstructions: val })} />
                                                )
                                                :
                                                <div>
                                                    <div>{label}</div>
                                                    <div>{value}</div>
                                                </div>
                                            }
                                        </div>
                                        {field !== "returnInstructions" && <>
                                            <div>
                                                <SliderField
                                                    label="Show always"
                                                    value={values.overridePermissions?.[field]?.showAlways !== undefined ? values.overridePermissions?.[field]?.showAlways : userData?.defaultPermissions?.[field]?.showAlways}
                                                    onChange={(val) => handleCheckboxChange(field, 'showAlways', val, setValues)}
                                                />
                                                <SliderField
                                                    label="Show when lost"
                                                    value={values.overridePermissions?.[field]?.showWhenLost !== undefined ? values.overridePermissions?.[field]?.showWhenLost : userData?.defaultPermissions?.[field]?.showWhenLost}
                                                    onChange={(val) => handleCheckboxChange(field, 'showWhenLost', val, setValues)}
                                                />
                                            </div>
                                        </>}
                                    </div>
                                ))}
                            </div>
                        </div>}

                    </div>
                )}
            </FormWrapper>
        </div>
    )
}