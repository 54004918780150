import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { api } from '../services/api.service.js'

import Loading from '../components/loading'

import { style } from '../style.js'

export default function FormWrapper({ url, id, callback, children, includeIdInPost, buttonText, hideButton, noStyle, defaultValue, noId, noLoad, validate, className, enableButtonOnChange }) {
    const [values, setValues] = useState()
    const [initValues, setInitValues] = useState()

    useEffect(() => {
        if (noId) {
            if (noLoad) {
                setValues(defaultValue ?? {})
                setInitValues(defaultValue ?? {})
            } else {
                api(`${process.env.REACT_APP_API_URL}/${url}`).then((x) => {
                    setValues(x ?? {})
                    setInitValues(x ?? {})
                })
            }
        } else {
            if (id !== 'new')
                api(`${process.env.REACT_APP_API_URL}/${url}/${id}`).then((x) => {
                    setValues(x ?? {})
                    setInitValues(x ?? {})
                })
            else {
                setValues(defaultValue ?? {})
                setInitValues(defaultValue ?? {})
            }
        }
    }, [id, defaultValue, noId, noLoad, url])

    function submit(data) {
        if (validate && !validate(values)) return

        if (includeIdInPost && !noId) {
            api(`${process.env.REACT_APP_API_URL}/${url}/${id}`, { data: data ?? values }).then((x) => {
                callback()
            })
        } else {
            api(`${process.env.REACT_APP_API_URL}/${url}`, { data: data ?? values }).then((x) => {
                callback()
            })
        }
    }

    if (!values) return <Loading></Loading>

    return (
        <div className={noStyle ? className ?? '' : '2xl:max-w-6xl max-w-5xl mx-auto my-5 bg-white shadow rounded p-5'}>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    submit()
                }}
            >
                {children(values, setValues, submit)}
                {!hideButton && <button disabled={!(!enableButtonOnChange || JSON.stringify(values) != JSON.stringify(initValues))} className={twMerge(style.button.classname, style.button._positive.classname, ' disabled:opacity-50 disabled:cursor-default inline-flex items-center justify-center mt-2')}>{buttonText ?? 'Submit'}</button>}
            </form>
        </div>
    )
}
