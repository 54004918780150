import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'

export default function PageHeader({ imageSrc, image, headline, actions, showBackButton, subtitle }) {
    const navigate = useNavigate()

    return (
        <div className='bg-white shadow-md'>
            <div className='flex flex-col items-start justify-center px-2 py-6 md:flex-row md:justify-between md:items-center sm:px-2 md:px-4 lg:mx-auto lg:max-w-6xl'>
                {showBackButton && (
                    <div onClick={() => navigate(-1)} className='mb-2 mr-4 transition rounded-lg shadow cursor-pointer bg-neutral-50 md:mb-0 hover:bg-neutral-200'>
                        <ArrowLeftIcon className='w-8 text-neutral-600'></ArrowLeftIcon>
                    </div>
                )}
                <div className='flex-1 min-w-0'>
                    <div className='flex items-center'>
                        {imageSrc && <img className='hidden w-16 h-16 mr-3 rounded-full sm:block' src={imageSrc} alt='Logo' />}
                        <div>
                            <div className='flex items-center'>
                                <h1 className='w-full text-2xl font-bold text-center text-gray-900 sm:truncate sm:text-left'>{headline}</h1>
                                {subtitle && <h3 className='text-lg font-semibold text-neutral-600 sm:truncate'>{subtitle}</h3>}
                            </div>
                        </div>
                    </div>
                </div>
                {actions && <div className='flex mt-6 space-x-3 md:mt-0 md:ml-4'>{actions}</div>}
            </div>
        </div>
    )
}
