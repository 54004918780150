import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthLayout from './layout';

export default function ResetEmail() {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    setValues({ ...values, token: searchParams.get('token'), id: searchParams.get('id') })
  }, []);

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-email`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(values)
    }).then(res => { return res.json() }).then(x => {
      if (x.status === "success") {
        setComplete(true)
      }
      else {
        setError("Failed to update email. Please try again or contact us for help.")
      }
    })
  }

  return (
    <>
      <AuthLayout>
        <div className="text-xl font-medium text-center text-neutral-800">Confirm New Email</div>

        {!complete && <>
          <div className="my-5 text-sm font-light text-center text-neutral-500">Click the button below to confirm your new email address</div>
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-center text-neutral-800">
                {error}
              </label>
            </div>

            <div>
              <button
                onClick={() => submit()}
                type="button"
                className="flex justify-center w-full px-4 py-2 font-semibold text-white border border-transparent rounded shadow-sm bg-brand-500 text-md hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
              >
                Confirm Email Address Change
              </button>
            </div>
          </div>

        </>}

        {complete && <div className="my-5 text-sm font-light text-center text-neutral-500">Email address update confirmed. You must now log back into Boomerang. <a className='text-brand text-xl block' href='/login'>Login Here</a></div>}
      </AuthLayout>
    </>
  )
}
