import SettingsForm from "../forms/settings-form";

export default function Settings() {

    return (
        <div className='bg-white rounded shadow p-5 mt-10'>
            <div className="text-neutral-700 text-center my-5">
                <div>Change any profile details and what can be seen by finders.</div>
                <div>If you don't leave any details, that's also OK, the finder will still be able
                    to send an anonymous email to you with return details.</div>
                <div>For individual items, adjust what can be seen by clicking “Manage” on
                    the My BoomerangTag page.</div>
            </div>
            <SettingsForm />
        </div>
    )
}
