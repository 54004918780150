import { useRef } from 'react';
// import QRCode from "react-qr-code"
import { useNavigate, useParams } from 'react-router-dom';
import { SliderField, TextAreaField, TextField } from './fields';
import FormWrapper from './form-wrapper';

export default function SettingsForm(props) {
  const svgRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  let id = params.id ?? props.id;

  const handleCheckboxChange = (field, subField, value, setValues) => {
    setValues(prev => ({
      ...prev,
      defaultPermissions: {
        ...prev.defaultPermissions,
        [field]: {
          ...prev.defaultPermissions?.[field],
          [subField]: value
        }
      }
    }))
  }

  return (
    <div>
      <FormWrapper url='app/profile' noStyle={true} noId={true} buttonText={'Save'} {...props} callback={() => window.location.reload()} enableButtonOnChange={true} >
        {(values, setValues) => (
          <div className='flex flex-col space-y-10 divide-y'>
            <div className='flex space-x-5'>
              <div className='flex-1'>
                <TextField label='First Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })} />
              </div>
              <div>
                <SliderField
                  label="Show always"
                  value={values.defaultPermissions?.name?.showAlways}
                  onChange={(val) => handleCheckboxChange('name', 'showAlways', val, setValues)}
                />
                <SliderField label="Show when lost"
                  value={values.defaultPermissions?.name?.showWhenLost}
                  onChange={(val) => handleCheckboxChange('name', 'showWhenLost', val, setValues)}
                />
              </div>
            </div>
            <div className='flex space-x-5'>
              <div className='flex-1'>
                <TextField label='Last Name' value={values.lastName} onChange={(val) => setValues({ ...values, lastName: val })} />
              </div>
              <div>
                <SliderField
                  label="Show always"
                  value={values.defaultPermissions?.lastName?.showAlways}
                  onChange={(val) => handleCheckboxChange('lastName', 'showAlways', val, setValues)}
                />
                <SliderField label="Show when lost"
                  value={values.defaultPermissions?.lastName?.showWhenLost}
                  onChange={(val) => handleCheckboxChange('lastName', 'showWhenLost', val, setValues)} />
              </div>
            </div>
            <div className='flex space-x-5'>
              <div className='flex-1'>
                <TextField type="email" required label='Email' value={values.email} onChange={(val) => setValues({ ...values, email: val })} />
                {values.tempEmail && <div>Email change to {values.tempEmail} in progress. Check your email to confirm this change.</div>}
              </div>
              <div>
                <SliderField
                  label="Show always"
                  value={values.defaultPermissions?.email?.showAlways}
                  onChange={(val) => handleCheckboxChange('email', 'showAlways', val, setValues)}
                />
                <SliderField label="Show when lost"
                  value={values.defaultPermissions?.email?.showWhenLost}
                  onChange={(val) => handleCheckboxChange('email', 'showWhenLost', val, setValues)} />
              </div>
            </div>
            <div className='flex space-x-5'>
              <div className='flex-1'>
                <TextField label='Phone Number' value={values.phoneNumber} onChange={(val) => setValues({ ...values, phoneNumber: val })} />
              </div>
              <div>
                <SliderField
                  label="Show always"
                  value={values.defaultPermissions?.phoneNumber?.showAlways}
                  onChange={(val) => handleCheckboxChange('phoneNumber', 'showAlways', val, setValues)}
                />
                <SliderField label="Show when lost"
                  value={values.defaultPermissions?.phoneNumber?.showWhenLost}
                  onChange={(val) => handleCheckboxChange('phoneNumber', 'showWhenLost', val, setValues)} />
              </div>
            </div>
            <div className='flex space-x-5'>
              <div className='flex-1'>
                <TextAreaField label='Default Return Instructions' value={values.defaultReturnInstructions} onChange={(val) => setValues({ ...values, defaultReturnInstructions: val })} />
              </div>
            </div>
          </div>
        )}
      </FormWrapper>
    </div>
  )
}