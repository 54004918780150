import { useNavigate, useParams } from 'react-router-dom'

import PageHeader from '../components/page-header'

import { CheckboxField, TextAreaField, TextField } from './fields'
import ImageField from './fields/image-upload'
import FormWrapper from './form-wrapper'
import S3Field from './fields/s3-field'

export default function ProductForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id

    return (
        <div>
            <PageHeader headline='Manage Product'></PageHeader>
            <FormWrapper url='admin/product' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        {/* <ImageField label='Name' value={values.photo} onChange={(val) => setValues({ ...values, photo: val })} /> */}
                        <S3Field label="Image" value={values.s3Image} onChange={(val) => setValues({ ...values, s3Image: val })} />

                        <TextField label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                        <TextField label='Brand' value={values.brand} onChange={(val) => setValues({ ...values, brand: val })}></TextField>

                        <TextField label='Size' value={values.size} onChange={(val) => setValues({ ...values, size: val })}></TextField>
                        <TextField label='Category' value={values.category} onChange={(val) => setValues({ ...values, category: val })}></TextField>
                        <TextField label='Colour' value={values.colour} onChange={(val) => setValues({ ...values, colour: val })}></TextField>
                        <TextField label='Style' value={values.style} onChange={(val) => setValues({ ...values, style: val })}></TextField>
                        <TextAreaField label='Additional Details' maxLength={240} value={values.additionalDetails} onChange={(val) => setValues({ ...values, additionalDetails: val })}></TextAreaField>

                        <div className='flex max-w-xl mx-auto mt-10 space-x-4'>
                            <div onClick={() => setValues({ ...values, defaultQrSettings: { ...(values.defaultQrSettings ?? {}), style: 'round' } })} className={`${values.defaultQrSettings?.style == 'round' ? 'bg-blue-500 text-white' : 'bg-white'} cursor-pointer shadow text-center flex-1 p-3 rounded-full`}>
                                Round
                            </div>
                            <div onClick={() => setValues({ ...values, defaultQrSettings: { ...(values.defaultQrSettings ?? {}), style: 'square' } })} className={`${values.defaultQrSettings?.style == 'square' ? 'bg-blue-500 text-white' : 'bg-white'} cursor-pointer shadow text-center flex-1 p-3 rounded-full`}>
                                Square
                            </div>
                        </div>

                        <TextField
                            className='max-w-xl mx-auto'
                            label='QR Code Text'
                            value={values.defaultQrSettings?.text}
                            onChange={(val) => {
                                setValues({ ...values, defaultQrSettings: { ...(values.defaultQrSettings ?? {}), text: val } })
                            }}
                        />
                        <CheckboxField
                            className='max-w-xl mx-auto'
                            label='Transparent Background'
                            value={values.defaultQrSettings?.transparentBackground}
                            onChange={(val) => {
                                setValues({ ...values, defaultQrSettings: { ...(values.defaultQrSettings ?? {}), transparentBackground: val } })
                            }}
                        />

                        <div className='max-w-xl mx-auto mt-5'>
                            <div>Logo</div>
                            <img src={values.defaultQrSettings?.logo}></img>
                            <ImageUpload setImage={(val) => setValues({ ...values, defaultQrSettings: { ...(values.defaultQrSettings ?? {}), logo: val } })} />
                        </div>
                    </>
                )}
            </FormWrapper>
        </div>
    )
}

const ImageUpload = ({ setImage }) => {
    const handleImageUpload = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()

        reader.onloadend = () => {
            setImage(reader.result)
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    return (
        <div>
            <input type='file' onChange={handleImageUpload} />
        </div>
    )
}
