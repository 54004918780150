import { Route, Routes } from 'react-router-dom'

import ErrorBoundary from './components/error-boundary'

import AdminCommunityRequest from './admin/admin-community-request'
import AdminCommunityRequests from './admin/admin-community-requests'
import AdminOrganisations from './admin/admin-organisations'
import AdminOwnerships from './admin/admin-ownerships'
import AdminProducts from './admin/admin-products'
import AdminUsers from './admin/admin-users'
import AnalyticsDashboard from './admin/analytics-dashboard'
import AdminLayout from './admin/layout'
import Communities from './app/communities'
import Contact from './app/contact'
import Home from './app/home'
import JoinOrganisation from './app/join-organisation'
import AppLayout from './app/layout'
import ListingDetail from './app/listing-detail'
import Marketplace from './app/marketplace'
import OwnershipDetails from './app/ownership-details'
import RequestCommunity from './app/request-community'
import Login from './auth/login'
import Logout from './auth/logout'
import Register from './auth/register'
import RequestReset from './auth/request-reset'
import ResetPassword from './auth/reset-password'
import CreateListingForm from './forms/create-listing-form'
import CustomOwnershipForm from './forms/custom-ownership-form'
import EditListingForm from './forms/edit-listing-form'
import OrganisationForm from './forms/organisation-form'
import OwnershipForm from './forms/ownership-form'
import ProductForm from './forms/product-form'
import UserForm from './forms/user-form'
import Settings from './app/settings'
import ResetEmail from './auth/reset-email'


export default function App() {
    return (
        <ErrorBoundary componentName='App (top level catch)'>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/request-reset' element={<RequestReset />} />
                <Route path='/passwordReset' element={<ResetPassword />} />
                <Route path='/email-reset' element={<ResetEmail />} />

                <Route path='/' element={<AppLayout />}>
                    <Route index element={<Home />} />
                    <Route path='/ownership/:id' element={<OwnershipDetails />} />
                    {/* <Route path='/organisation/:id' element={<JoinOrganisation />} />
                    <Route path='/join-organisation/:id' element={<JoinOrganisation />} /> */}
                    <Route path='/create-ownership/:id' element={<CustomOwnershipForm />} />
                    <Route path='/create-listing/:id' element={<CreateListingForm />} />
                    <Route path='/edit-listing/:id' element={<EditListingForm />} />
                    {/* <Route path='/marketplace' element={<Marketplace />} />
                    <Route path='/listing/:id' element={<ListingDetail />} /> */}
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/settings' element={<Settings />} />
                    {/* <Route path='/request-community' element={<RequestCommunity />} />
                    <Route path='/communities' element={<Communities />} /> */}
                </Route>
                <Route path='/admin' element={<AdminLayout />}>
                    <Route index element={<AdminUsers />} />
                    <Route path='/admin/manage-user/:id' element={<UserForm />} />
                    <Route path='/admin/products' element={<AdminProducts />} />
                    <Route path='/admin/manage-product/:id' element={<ProductForm />} />
                    <Route path='/admin/ownerships' element={<AdminOwnerships />}></Route>
                    <Route path='/admin/manage-ownership/:id' element={<OwnershipForm />}></Route>
                    <Route path='/admin/organisations' element={<AdminOrganisations />}></Route>
                    <Route path='/admin/manage-organisation/:id' element={<OrganisationForm />}></Route>
                    <Route path='/admin/analytics' element={<AnalyticsDashboard />}></Route>
                    <Route path='/admin/org-requests' element={<AdminCommunityRequests />}></Route>
                    <Route path='/admin/org-request/:id' element={<AdminCommunityRequest />}></Route>
                </Route>
            </Routes>
        </ErrorBoundary>
    )
}
