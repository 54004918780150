import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AuthLayout from './layout'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'

export default function Login() {
    const navigate = useNavigate()
    const [values, setValues] = useState({})
    const [error, setError] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    const [showPassword, setShowPassword] = useState(false)

    async function submit() {
        await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ user: values }),
        })
            .then((res) => {
                return res.json()
            })
            .then((x) => {
                if (x.token) {
                    localStorage.setItem('token', x.token)

                    if (searchParams.get('rd')) {
                        window.location.replace(searchParams.get('rd'))
                    } else {
                        window.location.replace(x.redirectUrl)
                    }
                } else {
                    setError('Login failed. Please check your details and try again.')
                }
            })
    }

    return (
        <AuthLayout>
            <div className='text-xl font-medium text-center text-neutral-800'>Sign In</div>
            <div className='mb-3 text-sm font-light text-center text-neutral-500'>
                Don't have an account?{' '}
                <a className='text-brand-400 hover:text-brand-500' href='/register'>
                    Sign Up
                </a>
            </div>

            <div className='my-5 text-sm font-light text-center text-neutral-500'>
                <p className="text-base font-bold">Welcome to Boomerang Community!</p>

                <p>Here you can Tag, Retrieve and Rehome your belongings</p>

                <p>Don't have a tag yet?</p>

                <p className="underline text-brand"><a href="https://theboomerangtag.com/collections/boomerangtag" target='_blank'>Get BoomerangTag here</a></p>
            </div>

            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    submit()
                }}
                className='space-y-6'
            >


                <div>
                    <label className='block text-sm font-medium text-center text-neutral-800'>{error}</label>
                </div>
                <div>
                    <label htmlFor='email' className='block text-sm font-medium text-neutral-800'>
                        Email address
                    </label>
                    <div className='mt-1'>
                        <input value={values.email} required onChange={(e) => setValues({ ...values, email: e.target.value })} id='email' name='email' type='email' autoComplete='email' className='block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm' />
                    </div>
                </div>

                <div>
                    <label htmlFor='password' className='block text-sm font-medium text-neutral-800'>
                        Password
                    </label>
                    <div className='mt-1 relative'>
                        <input value={values.password} required onChange={(e) => setValues({ ...values, password: e.target.value })} id='password' name='password' type={showPassword ? 'text' : 'password'} autoComplete='current-password' className='pr-7 block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm' />
                        {showPassword && <EyeSlashIcon onClick={() => setShowPassword(x => !x)} className="cursor-pointer w-6 text-neutral-600 right-1 top-2 hover:text-neutral-700 absolute z-10" />}
                        {!showPassword && <EyeIcon onClick={() => setShowPassword(x => !x)} className="cursor-pointer w-6 text-neutral-600 right-1 top-2 hover:text-neutral-700 absolute z-10" />}
                    </div>
                </div>

                <div className='flex items-center justify-between'>
                    <div className='text-sm'>
                        <a href='/request-reset' className='font-medium text-brand-600 hover:text-brand-500'>
                            Forgot your password?
                        </a>
                    </div>
                </div>

                <div>
                    <button type='submit' className='flex justify-center w-full px-4 py-2 font-semibold text-white border border-transparent rounded shadow-sm bg-brand-500 text-md hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2'>
                        Login
                    </button>
                </div>
            </form>
        </AuthLayout>
    )
}
